import React from 'react';

export interface HelpSvgProps {}

const HelpSvg: React.FunctionComponent<HelpSvgProps> = () => (
  <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 1.50369C0.107991 1.23219 0.194384 0.939803 0.345572 0.689189C0.712743 0.187961 1.2527 0 1.87905 0C5.33477 0 8.7905 0 12.2462 0C12.3542 0 12.4622 0 12.5486 0.0208845C13.4773 0.146192 14.1253 0.793612 14.1253 1.71253C14.1469 3.98894 14.1469 6.24447 14.1253 8.52088C14.1253 9.52334 13.3477 10.2125 12.311 10.2125C10.5184 10.2125 8.7473 10.2125 6.95464 10.2125C6.71706 10.2125 6.52268 10.2752 6.32829 10.4005C5.33477 11.0479 4.31965 11.6953 3.32613 12.3428C2.98056 12.5725 2.59179 12.5307 2.41901 12.2174C2.35421 12.113 2.33261 11.9668 2.33261 11.8415C2.33261 11.3612 2.33261 10.9017 2.33261 10.4214C2.33261 10.3587 2.33261 10.2961 2.33261 10.2125C2.15983 10.2125 2.03024 10.2125 1.87905 10.2125C0.907127 10.2543 0.12959 9.60688 0.0215983 8.75061C0.0215983 8.72973 0 8.70885 0 8.70885C0 6.30713 0 3.90541 0 1.50369ZM7.06264 4.53194C7.90497 4.53194 8.7473 4.53194 9.58963 4.53194C10.1296 4.53194 10.6695 4.53194 11.2095 4.53194C11.6199 4.53194 11.8575 4.23956 11.7711 3.84275C11.7063 3.52948 11.5119 3.38329 11.1231 3.38329C8.42333 3.38329 5.70194 3.38329 3.00216 3.38329C2.93737 3.38329 2.89417 3.38329 2.82937 3.38329C2.59179 3.42506 2.41901 3.57125 2.37581 3.7801C2.26782 4.21867 2.5054 4.51106 2.98056 4.51106C4.34125 4.53194 5.70194 4.53194 7.06264 4.53194ZM5.31318 5.65971C4.53564 5.65971 3.7365 5.65971 2.95896 5.65971C2.63499 5.65971 2.4406 5.8059 2.37581 6.05651C2.26782 6.4742 2.527 6.78747 2.95896 6.78747C3.99568 6.78747 5.0324 6.78747 6.06912 6.78747C6.60907 6.78747 7.12743 6.78747 7.66739 6.78747C7.96976 6.78747 8.16415 6.64128 8.22894 6.39066C8.33693 5.97297 8.07775 5.65971 7.62419 5.65971C6.86825 5.65971 6.09071 5.65971 5.31318 5.65971Z'
      fill='white'
    />
    <path
      d='M15.3122 4.53198C15.377 4.53198 15.4202 4.53198 15.4634 4.53198C16.3705 4.53198 17.2776 4.53198 18.1847 4.53198C19.0703 4.53198 19.7182 5.01233 19.9342 5.80594C19.9774 5.97301 19.999 6.14009 19.999 6.30717C19.999 8.52093 19.999 10.7347 19.999 12.9276C19.999 13.9927 19.2431 14.7027 18.1416 14.7236C17.9904 14.7236 17.8392 14.7236 17.6664 14.7236C17.6664 15.1413 17.6664 15.559 17.6664 15.9558C17.6664 16.0811 17.6664 16.2273 17.6664 16.3526C17.6664 16.5824 17.6016 16.7912 17.364 16.9165C17.1264 17.0418 16.9105 17 16.6945 16.8747C16.5001 16.7703 16.3273 16.645 16.1329 16.5197C15.269 15.9558 14.3835 15.3919 13.5195 14.8281C13.4331 14.7654 13.3035 14.7236 13.174 14.7236C11.3381 14.7236 9.52384 14.7236 7.68799 14.7236C6.93205 14.7236 6.37049 14.4312 6.02492 13.7629C5.91693 13.5332 5.87373 13.2617 5.85214 12.9902C5.83054 12.7187 5.83054 12.4263 5.85214 12.1548C5.85214 12.0922 5.89533 12.0086 5.96013 11.9669C6.2625 11.758 6.56488 11.5701 6.86725 11.3821C6.95365 11.3403 7.04004 11.3195 7.14803 11.3195C8.87589 11.3195 10.6038 11.3195 12.3532 11.3195C13.8003 11.3195 14.945 10.4005 15.2474 9.06392C15.2906 8.83419 15.3122 8.60446 15.3122 8.39562C15.3122 7.12166 15.3122 5.84771 15.3122 4.53198Z'
      fill='white'
    />
  </svg>
);

export default HelpSvg;
